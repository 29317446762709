import * as React from 'react';
import { useState } from 'react';
import { graphql } from 'gatsby';
import { useForm } from "react-hook-form";
import { callHook } from '../utils/utils';

export const BusinessCardReader = ({ slice, index }) => {
  const { title } = slice.primary;

  const [isImageSend, setImageSend] = useState(false);
  const [isDataSend, setDataSend] = useState(false);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [qrUrl, setQrUrl] = useState("")
  const [fileUrl, setFileUrl] = useState("")
  const [parsedData, setParsedData] = useState("")
  const [tesseractText, setSesseractText] = useState("")
  const [token, setToken] = useState("");
  const [passkitId, setPasskitId] = useState("");

  const { register, handleSubmit, formState: { errors, isSubmitted } } = useForm({
    mode: "onChange"
  });

  const isFirst = index === 0;

  if (typeof window !== 'undefined') {
    const pageUrl = window.location;
    //console.log('pageUrl: ', pageUrl);
  }

  const onSubmitImage = async (data) => {
    const formData = new FormData();
    formData.append('file', data.file[0]);

    setLoading(true);
    try {
      const response = await fetch('https://n8n.yohn.io/webhook/bcscan', {
        method: 'POST',
        body: formData,
        headers: {
          'Accept': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log('responseData: ', responseData);
        if (responseData) {
          //setQrUrl(responseData.img);
          //setFileUrl(URL.createObjectURL(new Blob([responseData.vcf], { type: 'text/x-vcard;charset=utf-8' })))
          setParsedData(responseData.parsed_data)
          setSesseractText(responseData.tesseract_text)
          setError(false);
        } else {
          setError(true);
        }
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    }
    setImageSend(true);
    setLoading(false);
  };

  let gclid = ""
  let msclkid = ""
  if (typeof window !== 'undefined'){
    const pageUrl = new URL(window.location);
    gclid = pageUrl.searchParams.get("gclid");
    //console.log('gclid: ', gclid);
    msclkid = pageUrl.searchParams.get("msclkid");
    //console.log('msclkid: ', msclkid);
  }

  const onSubmitData = async (data) => {
    setImageSend(false);
    const url = "https://n8n.yohn.io/webhook/free-business-card"
    const mode = "no-cors"
    let qrData = {type: "FreeBusinessCard", email: parsedData.email, parsedData: "", sendEmail: data.sendEmail, gclid: gclid, msclkid: msclkid}

    const phone = Array.isArray(parsedData.phone) ? parsedData.phone[0] : (parsedData.phone?.mobile || parsedData.phone?.office || parsedData.phone?.fax);
    const email = Array.isArray(parsedData.email) ? parsedData.email[0] : parsedData.email;

    const person = {
      "surname": parsedData.lastName,
      "forename": parsedData.firstName,
      "emailAddress": parsedData.email[0],
      "mobileNumber": phone
    };

    const text = [
      "BEGIN:VCARD",
      "VERSION:3.0",
      `N:${parsedData.lastName};${parsedData.firstName}`,
      `FN:${parsedData.firstName} ${parsedData.lastName}`,
    ];

    if (email) {
      text.push(`EMAIL;TYPE=INTERNET:${email}`);
    }

    if (phone) {
      text.push(`TEL;CELL:${phone}`);
      if (parsedData.phone.office) {
        text.push(`TEL;type=WORK:${parsedData.phone || parsedData.phone.office}`);
      }
      if (parsedData.phone.fax) {
        text.push(`TEL;type=WORK,FAX:${parsedData.phone || parsedData.phone.fax}`);
      }
      
    }
    text.push("END:VCARD")

    const qr_pass = {
      "person": person,
      "vcard": text.join("\r\n")
    }

    qrData.data = text.join('\r\n');

    console.log('qr_pass: ', qr_pass);

    qrData.qrPass = qr_pass;
    window.scrollTo(0, 0)
    setLoading(true);
    const r = await callHook({url, uid:"yohn-qr-reader", params:qrData, mode});
    console.log('callHook r: ', r);
    if (r && r.success === true) {
      setQrUrl(r.qr);
      setError(false);
      setDataSend(true);
      setLoading(false);
      setToken(r.userToken)
      setPasskitId(r.passkitId)
    } else {
      setError(true);
    }
    setDataSend(true);
    setLoading(false);
  };

  if (isLoading && !isImageSend && !isDataSend) {
    return (
      <div className="py-6 bg-color-1">
			  <div className="container">
          {isFirst ? <h1 className="h2 text-center mb-5">{title}</h1> : <h2 className="text-center mb-5">{title}</h2>}
          <div className="text-center">
            <div className="loader mx-auto"></div>
          </div>
        </div>
      </div>
    );
  }

  const displayNames = {
    name: "Name",
    email: "Email",
    phone: "Phone",
    address: "Address",
    company: "Company",
    website: "Website"
  };
  const excludedKeys = ["firstName", "lastName"];
  const specialKeys = [ "name", "email"];



  const renderValue = (key, value) => {
    if (Array.isArray(value)) {
      return (
        <ul>
          {value.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      );
    } else if (typeof value === 'object' && value !== null) {
      return (
        <ul>
          {Object.entries(value).map(([subKey, subValue]) => (
            <li key={subKey}><strong>{subKey}:</strong> {subValue}</li>
          ))}
        </ul>
      );
    } else {
      return value;
    }
  };


  if (isImageSend) {
    return (
      <div className="py-6 bg-color-1">
			  <div className="container">
          {isFirst ? <h1 className="h2 text-center mb-5">{title}</h1> : <h2 className="text-center mb-5">{title}</h2>}
          <div className="text-center">
            {!isError ?
              <div className="text-center">
                {/*<img src={qrUrl} alt="qr-code" width="160" height="160"/>              
                <div className="mt-4">
                  <a href={fileUrl} download="business-card.vcf">Download VCF</a>
                </div>*/}
                <div className="d-flex flex-column align-items-center">
                  {(parsedData.firstName && parsedData.lastName && parsedData.email) ?
                    <>
                      <p>{"Check that the details from your business card were scanned correctly. If there are mistakes, please upload a new photo and try again."}</p>
                      <ul className="list-group mt-2" style={{width:"fit-content"}}>
                      {Object.entries(parsedData).map(([key, value]) => (
                        specialKeys.includes(key) && !excludedKeys.includes(key) && value && (
                          <li key={key} className={`text-start list-group-item special-${key}`}>
                            <strong>{`${displayNames[key] || key}: `}</strong> 
                            {renderValue(key, value)}
                          </li>
                        )
                      ))}
                      {Object.entries(parsedData).map(([key, value]) => (
                        !specialKeys.includes(key) && !excludedKeys.includes(key) && value && (
                          <li key={key} className="text-start list-group-item">
                            {`${displayNames[key] || key}: `}
                            {renderValue(key, value)}
                          </li>
                        )
                      ))}
                      </ul>
                    </>
                  :
                    <p>{"Unfortunately, we couldn't scan your business card. Please upload a new photo, ensuring that your email address, first name, and last name are clearly visible."}</p>  
                  }
                  {/*<p className="mt-3 list-group list-group-item">{tesseractText}</p>*/}
                </div>
              </div>
            :
              <div className="text-center">
                <h3>An error has occurred</h3>
                <p>Please try again later.</p>
              </div>
            }
            <div className="mt-4 d-flex flex-column align-items-center gap-3">
              {(!isError && parsedData.firstName && parsedData.lastName && parsedData.email) && 
                <form onSubmit={handleSubmit(onSubmitData)}>
                  <label className="form-check-label" for="flexCheckChecked">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      {...register('sendEmail', {required: false})}
                    />
                    <span className="fs-0875">Send a link to my digital business card to my email address.</span>
                  </label>
                  <div className="mt-3">
                    <button type="submit" className={"btn btn-sm text-uppercase btn-primary"}>{"Continue"}</button>
                  </div>
                </form>
              }
              <button onClick={()=>{setImageSend(false),setDataSend(false)}} className={"btn btn-sm text-uppercase btn-secondary"}>{"Try again"}</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isDataSend) {
    return (
      <div className="py-6 bg-color-1">
			  <div className="container">
          {isFirst ? <h1 className="h2 text-center mb-4">{title}</h1> : <h2 className="text-center mb-5">{title}</h2>}
          <div className="text-center">
            {!isError ?
              <div className="text-center">
                <img src={qrUrl} alt="qr-code" width="200" height="200"/>              
              </div>
            :
              <div className="text-center">
                <h3>An error has occurred</h3>
                <p>Please try again later.</p>
              </div>
            }
            <div className="mt-4">
              <button onClick={()=>{setImageSend(false),setDataSend(false)}} className={"btn btn-sm text-uppercase btn-secondary"}>{"Try again"}</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="py-6 bg-color-1">
			<div className="container">
        {isFirst ? <h1 className="h2 text-center mb-5">{title}</h1> : <h2 className="text-center mb-5">{title}</h2>}
        <div className="col-12 col-md-9 col-lg-7 mx-auto">
          <form className="text-center" onSubmit={handleSubmit(onSubmitImage)}>
            <div className="form-group">
              <input type="file" id="file" {...register('file', { required: true })} className="form-control" />
              {errors.file && <p>File is required.</p>}
            </div>
            <button type="submit" className={"mt-4 btn btn-sm btn-primary text-uppercase"}>Submit</button>
          </form>
        </div>
			</div>
		</div>
  )
};

export const query = graphql`
  fragment PageDataBodyBusinessCardReader on PrismicPageDataBodyBusinessCardReader {
    primary {
      title
    }
  }
`;

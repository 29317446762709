import * as React from 'react'
import { graphql } from 'gatsby'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

export const BlogFeaturesTable = ({ slice }) => {
  const {title, opponent} = slice.primary

  const tableRows = slice.items.map((item, index) => {
    return (
      <tr key={index}>
		  	<td scope="row">
		  		<h6 className="text-start">{item.title} <a tabIndex="0" className="icon-popover d-inline-block d-md-none" data-bs-toggle="popover" data-bs-trigger="focus" data-bs-title="Unlimited Branded Links" data-bs-content="Yohn uses AWS backend. This allows you to store a virtually unlimited number of shortlinks. The first 1 trillion will be for free :)"><i className="fas fa-question-circle icon-popover d-inline-block d-md-none"></i></a>
					<span className="d-inline-block d-md-none">
						<OverlayTrigger
        		  trigger="click"
        		  overlay={
        		    <Popover id={`popover-positioned`}>
        		      <Popover.Header as="h3">{item.title}</Popover.Header>
        		      <Popover.Body>{item.description.text}</Popover.Body>
        		    </Popover>
        		  }
        		>
        		  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="ms-1 mb-1 bi bi-question-circle-fill" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/></svg>
        		</OverlayTrigger>
					</span>
					</h6>
		  		<div className="d-none d-md-block text-start" style={{fontSize:"14px"}}>{item.description.text}</div>
		  	</td>
		  	<td className="td-center"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#18c884" className="bi bi-check" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/></svg></td>
		  	<td className="td-center"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#f44d61" className="bi bi-x" viewBox="0 0 16 16"><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/></svg></td>
		  </tr>
    )
  })

  return (
    <div className="mt-5">
				<h4 className="mb-3">{title}</h4>
				<table className="table table-light table-bordered">
					<thead className="table-dark">
						<tr>
							<th scope="col"><h6>Features</h6></th>
							<th scope="col" className="text-center mark-col"><h6>Yohn</h6></th>
							<th scope="col" className="text-center mark-col"><h6>{opponent}</h6></th>
						</tr>
					</thead>
					<tbody>
						{tableRows}
					</tbody>
				</table>
		</div>
  )
}

export const query = graphql`
  fragment BlogItemDataBodyBlogFeaturesTable on PrismicBlogItemDataBodyBlogFeaturesTable {
		slice_type
    primary {
      title
      opponent
    }
    items {
      title
      description {
        text
        richText
      }
    }
  }
`

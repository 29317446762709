import React, { useState, useEffect } from 'react';
import { graphql } from "gatsby"
import { SliceZone } from '@prismicio/react'
import * as prismicH from "@prismicio/helpers";
import { BlogPostJsonLd } from 'gatsby-plugin-next-seo';
import moment from 'moment';
import { Layout } from '../../components/Layout'
import { components } from '../../slices'
import { BlogListItem } from '../../components/BlogListItem';

function BlogItem(props) {
  //console.log('BlogItem props: ', props)
  const { prismicBlogItem, prismicBlog } = props.data
  const blogItem = prismicBlogItem.data
  // const eventInfoSlice = event.body.find(item => item.slice_type === 'event_info');
  const seo = {
    title: blogItem.seo_title || prismicBlogItem.data.title,
    description: blogItem.seo_description || prismicBlogItem.data.subtitle,
    keywords: blogItem.seo_keywords,
    no_robots: blogItem.no_robots,
  }
  //console.log('seo: ', seo)

  const {title, subtitle, date, timestamp, cover_img, body, author_name, author_img, author_link} = blogItem

  const topMenu = props.data.prismicTopMenu || {}
  const footer = props.data.prismicFooter || {}

  const [blogItems,setBlog] = useState([])
  
  useEffect( ()=> {
    const docs = props.data.allPrismicBlogItem || []
    const now = moment()
    const filtered = docs.nodes.filter(item => {
      //console.log('item: ', item)
      const data = item.data
      // console.log('start: ',data.start_time)
      // console.log('finish: ',data.finish_time)
      // const startTime = moment(data.start_time)
      // const finishTime = moment(data.finish_time)
      // return (startTime < now)
      return true;
    })
    //console.log('portfolio: ', filtered)
    setBlog(filtered)
  }, [props.data.allPrismicBlogItem])
  
  if (!props.data) return null

  const blog = props.data.prismicBlog || {}

  //Firefox GIF fix
  let cover_img_url = cover_img.url
  
  if (new URL(cover_img_url).pathname.endsWith(".gif")) {
    cover_img_url= prismicH.asImageSrc(cover_img, {auto: "compress"})
  }

  let timestampSplit = ""
  if (timestamp) {
    timestampSplit = timestamp.split('T')
  }

  return (
    <Layout topMenu={topMenu.data} footer={footer.data} seo={seo}>
      <BlogPostJsonLd
        url={"https://www.yohn.io" + prismicBlogItem.url}
        title={title}
        images={cover_img_url}
        datePublished={timestamp}
        dateModified={timestamp}
        authorName={author_name ? author_name : "Yohn Team"}
        description={subtitle}
      />
      <div className="bg-light py-5">
        <div className="container mb-3 d-flex align-items-center">
          <a className="text-dark h6 mb-0" href="/">Home</a>
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#404050bf" className="text-dark mx-1 bi bi-chevron-right" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg>
          <a className="text-dark h6 mb-0" href="/blog/">Blog</a>
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#404050bf" className="mx-1 bi bi-chevron-right" viewBox="0 0 16 16"><path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg>
          <span className="text-dark h6 mb-0 opacity-75">{title}</span>
        </div>
        <div className="container py-5 px-3 px-sm-4 px-lg-5 bg-white">
          <h1 className="h2">{title}</h1>
          <p className="h5">{subtitle}</p>
          <div className="mt-4 d-flex">
            <a href={author_link.url} target="_blank">
              <img src={author_name ? (author_img.url ? author_img.url : "/icons/icon-user.png") : "/favicon.png"} alt={(author_name ? author_name : "Yohn Team") + " image"} className="rounded-circle" height="48" width="48"/>
            </a>
            <div className="ms-3 d-flex flex-column justify-content-between">
              <a href={author_link.url} target="_blank" className="text-decoration-none text-body">
                <div className="h5 mb-0">{author_name ? author_name : "Yohn Team"}</div>
              </a>
              <div className="" style={{fontSize:"14px"}}>{timestamp ? timestampSplit[0] : date}</div>
            </div>
          </div>
          <hr/>
          <div className="my-5 w-100 text-center">
            <img src={cover_img_url} className="img-fluid" style={{maxHeight:"360px"}} alt={cover_img.alt}/>
          </div>
          <SliceZone slices={body} components={components} />
        </div>
        <div className="mt-5 container">
          <h3 className="">{"Recent articles:"}</h3>
        </div>
        <div className="mt-4 container py-5 px-3 px-sm-4 px-lg-5 bg-white"> 
          {
            blogItems.map((item, index) => (
              <div key={index} className="">
                <BlogListItem item={item} recent={true} />
              </div>))
          }
        </div>
      </div>
    </Layout>
  )
}

export default BlogItem

export const query = graphql`
  query($id: String!) {
    prismicBlogItem(id: { eq: $id }) {
      uid
      url
      type
      data {
        title
        subtitle
        date
        timestamp
        cover_img {
          url
          alt
        }
        author_name
        author_link {
          url
        }
        author_img {
          url
        }
        seo_title
        seo_description
        seo_keywords

        body {
          ...BlogItemDataBodyBlogText
          ...BlogItemDataBodyBlogFeaturesTable
          ...BlogItemDataBodyBlogPricing
          ...BlogItemDataBodyBlogPurchaseForm
          ...BlogItemDataBodyTreesCalc
          ...BlogItemDataBodyTable
        }
      }
    }
    prismicTopMenu {
      ...TopMenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }

    allPrismicBlogItem(
    sort: { fields: data___timestamp, order: DESC }
    limit: 3
    skip: 0
    filter: {id: {ne: $id}}
    ) {
      nodes {
        _previewable
        uid
        url
        data {
          title
          subtitle
          date
          timestamp
          cover_img {
            alt
            url
          }
          author_name
          author_link {
            url
          }
          author_img {
            url
          }
          #sort
          body {
            ... on PrismicBlogItemDataBodyBlogText {
              id
              slice_type
              primary {
                text {
                  richText
                }
              }
            }
          }
        }
      }
    }
  }
`
import { MainCover } from './MainCover'
import { ImagesAndText } from './ImagesAndText'
import { Features } from './Features'
import { ShortForm } from './ShortForm'
import { Pricing } from './Pricing'
import { PurchaseForm } from './PurchaseForm'
import { AccordionFaq } from './AccordionFaq'

import { SeparateHeader } from './SeparateHeader'

import { PageCover } from './PageCover'
import { FeaturesTable } from './FeaturesTable'
import { TitleAndText } from './TitleAndText'
import { ContactForm } from './ContactForm'
import { AdditionalFooterInfo } from './AdditionalFooterInfo'
import { SuccessfulPurchase } from './SuccessfulPurchase'
import { ClientInfoForm } from './ClientInfoForm'
import { TableFourCols } from './TableFourCols'

import { BlogText } from './BlogText'
import { BlogFeaturesTable } from './BlogFeaturesTable'
import { BlogPricing } from './BlogPricing'
import { BlogPurchaseForm } from './BlogPurchaseForm'
import { Table } from './Table'

import { GetColibriPage } from './GetColibriPage'

import { PricingNew } from './PricingNew'
import { MainFeatures } from './MainFeatures'

import { ReportUrlForm } from './ReportUrlForm'
import { QrCodeGenerator } from './QrCodeGenerator'
import { QrLinks } from './QrLinks'
import { DynamicQrLink } from './DynamicQrLink'
import { YohnPremium } from './YohnPremium'

import { CardFormats } from './CardFormats'
import { CardInAction } from './CardInAction'

import { LostAndFound } from './LostAndFound'
import { CreateWalletCard } from './CreateWalletCard'
import { BusinessCardForm } from './BusinessCardForm'
import { PremiumBusinessCardForm } from './PremiumBusinessCardForm'
import { BusinessCardReader } from './BusinessCardReader'
import { BusinessCardLanding } from './BusinessCardLanding'

/*new homepage*/
import { HeroSection } from './HeroSection'
import { CustomerReviews } from './CustomerReviews'
import { FeaturesNew } from './FeaturesNew'
import { ImagesAndTextNew } from './ImagesAndTextNew'
import { CustomOffer } from './CustomOffer'

import { HeroSlider } from './HeroSlider'
import { Features2 } from './Features2'
import { Features3 } from './Features3'
import { ImageAndText } from './ImageAndText'
import { VersatileForms } from './VersatileForms'
import { ContactForm2 } from './ContactForm2'
import { TreesCalc } from './TreesCalc'

import { FeaturesNumList } from './FeaturesNumList'
import { Features5 } from './Features5'
import { ContactForm3 } from './ContactForm3'
import { HeroBgImage } from './HeroBgImage'
import { EmbedHtml } from './EmbedHtml'
import { Partners } from './Partners'
import { LangsHeader } from './LangsHeader'

export const components = {
  main_cover: MainCover,
  images_and_text: ImagesAndText,
  features: Features,
  short_form: ShortForm,
  pricing: Pricing,
  purchase_form: PurchaseForm,
  accordion_faq: AccordionFaq,

  separate_header: SeparateHeader,
  
  page_cover: PageCover,
  features_table: FeaturesTable,
  title_and_text: TitleAndText,
  contact_form: ContactForm,
  additional_footer_info: AdditionalFooterInfo,
  successful_purchase: SuccessfulPurchase,
  client_info_form: ClientInfoForm,
  table_four_cols: TableFourCols,

  blog_text: BlogText,
  blog_features_table: BlogFeaturesTable,
  blog_pricing: BlogPricing,
  blog_purchase_form: BlogPurchaseForm,
  table: Table,

  get_colibri_page: GetColibriPage,

  pricing_new: PricingNew,
  main_features: MainFeatures,

  report_url_form: ReportUrlForm,
  qr_code_generator: QrCodeGenerator,
  qr_links: QrLinks,
  dynamic_qr_link: DynamicQrLink,
  yohn_premium: YohnPremium,

  card_formats:CardFormats,
  card_in_action:CardInAction,

  lost_and_found: LostAndFound,
  create_wallet_card: CreateWalletCard,
  business_card_form: BusinessCardForm,
  premium_business_card_form: PremiumBusinessCardForm,
  business_card_reader: BusinessCardReader,
  business_card_landing: BusinessCardLanding,

  /*new homepage*/
  hero_section: HeroSection,
  customer_reviews:CustomerReviews,
  features_new: FeaturesNew,
  images_and_text_new: ImagesAndTextNew,
  custom_offer: CustomOffer,

  hero_slider: HeroSlider,
  features_2: Features2,
  features_3: Features3,
  image_and_text: ImageAndText,
  versatile_forms: VersatileForms,
  contact_form_2: ContactForm2,
  trees_calc: TreesCalc,

  features_num_list: FeaturesNumList,
  features_5: Features5,
  contact_form_3: ContactForm3,
  hero_bg_image: HeroBgImage,
  embed_html: EmbedHtml,
  partners: Partners,

  langs_header: LangsHeader
}

import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';

export const Footer = ({ footer }) => {
  const {copyright, top_text, lin_link, fb_link, tw_link, bottom_text, footer_links} = footer;
  
  const links = footer_links.map(footerLink => {
    const link = footerLink.link.url;
    if (link.startsWith('tel')) return footerLink;
    if (link.startsWith('http')) return footerLink;
    if (link.endsWith('/')) return footerLink;
    footerLink.link.url = link + '/';
    return footerLink;
  });

  const company = footer ? links.map((footerLink, index) => (
    (footerLink.column == "Company") &&
    <div key={index} className="col-auto text-white"><a href={footerLink.link.url}><div className="h5 fw-normal">{footerLink.label}</div></a></div>
  )) : null

  const documents = footer ? links.map((footerLink, index) => (
    (footerLink.column == "Legal documents") &&
    <div key={index} className="col-auto"><a href={footerLink.link.url}><div className="h5 fw-normal">{footerLink.label}</div></a></div>
  )) : null

  const contacts = footer ? links.map((footerLink, index) => (
    (footerLink.column == "Contacts") &&
    <div key={index} className="col-auto"><a href={footerLink.link.url}><div className="h5 fw-normal">{footerLink.label}</div></a></div>
  )) : null

  const qrs = footer ? links.map((footerLink, index) => (
    (footerLink.column == "QR") &&
    <div key={index} className="col-auto"><a href={footerLink.link.url}><div className="h5 fw-normal">{footerLink.label}</div></a></div>
  )) : null

  const social = (
    <div className="d-flex mt-3">
      <a href={lin_link.url} target="_blank" className="me-3">
        <img src="/icons/linkedin-2-white.svg" height="28" width="28" alt="LinkedIn link"/>
      </a>
      <a href={fb_link.url} target="_blank" className="me-3">
        <img src="/icons/facebook-white.svg" height="28" width="28" alt="Facebook link"/>
      </a>
      <a href={tw_link.url} target="_blank">
        <img src="/icons/twitter-2-white.svg" height="28" width="28" alt="Twitter link"/>
      </a>
    </div>
  )

  const handleChange = event => {
    changeCurrency(event.target.value)
  };

  /*let selectedCurrency = ""
  if (typeof window !== 'undefined'){
    selectedCurrency = localStorage["currency"]
  }*/

  const currency = (
    <Form.Select id="currency-selector" className="w-auto mt-3" onChange={handleChange} /*defaultValue={selectedCurrency}*/>
      <option value="USD">USD</option>
      <option value="EUR">EUR</option>
      <option value="GBP">GBP</option>
      <option value="AUD">AUD</option>
    </Form.Select>
  )

  return (
    <footer className="footer bg-footer flex-fill">
      <div className="container py-5">
        <div className="row">
          <div className="col-12 col-lg-2 mb-4">
            <img src="/yohn-logo-light.png" height="36" width="115" className="d-inline-block align-top" alt="Yohn logo light"/>
            {social}
            {currency}
            <img src="/startup-verband-light.svg" className="d-inline-block align-top mt-4" style={{maxWidth:"160px"}} alt="Startup-Verband logo"/>
            <img src="/german-accelerator-logo-light.svg" className="d-inline-block align-top mt-3" style={{maxWidth:"160px"}} alt="Startup-Verband logo"/>
          </div>
          {/*desktop nav*/}
          <div className="col-10 row d-none d-lg-flex">
            <div className="col-3 mb-4 mb-lg-0">
              <div className="h4 mb-3">Useful links</div>
              {company}
              <div className="col-auto text-white"><a href="/login/"><div className="h5 fw-normal">Login</div></a></div>
            </div>
            <div className="col-3 mb-4 mb-lg-0">
              <div className="h4 mb-3">Legal documents</div>
              {documents}
            </div>
            <div className="col-3 mb-4 mb-lg-0">
              <div className="h4 mb-3">Contacts</div>
              {contacts}
            </div>
            <div className="col-3 mb-4 mb-lg-0">
              <div className="h4 mb-3">Free QR code generators</div>
              {qrs}
            </div>
          </div>
          {/*mobile nav*/}
          <div className="col-12 d-block d-lg-none">
            <Accordion className="accordion-dark">
              <Accordion.Item eventKey={"company"} key={"company"}>
                <Accordion.Header as="div" className="h2">{"Company"}</Accordion.Header>
                <Accordion.Body>{company}</Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey={"documents"} key={"documents"}>
                <Accordion.Header as="div" className="h2">{"Legal documents"}</Accordion.Header>
                <Accordion.Body>{documents}</Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey={"contacts"} key={"contacts"}>
                <Accordion.Header as="div" className="h2">{"Contacts"}</Accordion.Header>
                <Accordion.Body>{contacts}</Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey={"qr-generators"} key={"qr-generators"}>
                <Accordion.Header as="div" className="h2">{"Free QR code generators"}</Accordion.Header>
                <Accordion.Body>{qrs}</Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        <div className="row gy-3 mt-5">
          <div className="col-12 col-lg-4 order-3 order-lg-1">
              <div className="h6">{copyright.text}</div>
          </div>
          <div className="col-12 col-lg-4 text-lg-center order-2">
          <div className="h6">{bottom_text.text}</div>
          </div>
          <div className="col-12 col-lg-4 text-lg-end order-1 order-lg-3">
            <a href="#top"><span className="h6">Back to top<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-up-short" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"/></svg></span></a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export const query = graphql`
  fragment FooterFragment on PrismicFooter {
    id
    type
    data {
      copyright {
        richText
        text
      }
      bottom_text {
        richText
        text
      }
      lin_link {
        url
      }
      fb_link {
        url
      }
      tw_link {
        url
      }
      footer_links {
        label
        link {
          url
        }
        column
      }
    }
  }
`
import React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Table = ({ slice }) => {

  const rows = [];
  for (let i = 0; i < slice.items.length; i += slice.primary.num_of_columns) {
    rows.push(slice.items.slice(i, i + slice.primary.num_of_columns));
  }
 
  console.log(rows)
  
  return (
    <div>
      <table className="table table-light table-bordered">
        <thead className="table-dark">
          <tr>
            {rows.length > 0 && rows[0].map((item, index) => (
              <th key={index} className="no-mb-richtext">
                <PrismicRichText field={item.table_cell.richText}/>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.slice(1).map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((item, cellIndex) => (
                <td key={cellIndex} className="no-mb-richtext" style={{fontSize:"14px"}}>
                  <PrismicRichText field={item.table_cell.richText}/>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export const query = graphql`
  fragment BlogItemDataBodyTable on PrismicBlogItemDataBodyTable {
		slice_type
    primary {
      num_of_columns
    }
    items{
      table_cell {
        richText
        text
      }
    }
  }
`

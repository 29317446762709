import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'
import { PrismicRichText } from '@prismicio/react'
import { Layout } from '../components/Layout'
import { components } from '../slices'

const PageTemplate = ({ data }) => {
  if (!data) return null

  const pageContent = data.prismicPage
  const page = pageContent.data || {}
  const topMenu = data.prismicTopMenu || {}
  const footer = data.prismicFooter || {}

  const seo = {
    title: page.seo_title,
    description: page.seo_description,
    keywords: page.seo_keywords,
    no_robots: page.no_robots,
  }

  const hideHeader = page.hide_header
  const hideFooter = page.hide_footer

  const { lang, type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const cntx = {url};

  return (
    <Layout topMenu={topMenu.data} footer={footer.data} activeDocMeta={activeDoc} seo={seo} hideHeader={hideHeader} hideFooter={hideFooter}>
      <SliceZone slices={page.body} components={components} context={cntx}/>
    </Layout>
  )
}

export const query = graphql`
  query pageQuery($id: String, $lang: String) {
    prismicPage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      id
      lang   
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        hide_header
        hide_footer
        seo_title
        seo_description
        seo_keywords
        no_robots

        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...PageDataBodyPageCover
          ...PageDataBodyMainCover
          ...PageDataBodySeparateHeader
          ...PageDataBodyFeaturesTable
          ...PageDataBodyPurchaseForm
          ...PageDataBodyTitleAndText
          ...PageDataBodyContactForm
          ...PageDataBodyAdditionalFooterInfo
          ...PageDataBodySuccessfulPurchase
          ...PageDataBodyShortForm
          ...PageDataBodyClientInfoForm
          ...PageDataBodyAccordionFaq
          ...PageDataBodyTableFourCols
          ...PageDataBodyGetColibriPage
          ...PageDataBodyImagesAndText
          ...PageDataBodyFeatures
          ...PageDataBodyPricing
          ...PageDataBodyPricingNew
          ...PageDataBodyMainFeatures
          ...PageDataBodyReportUrlForm
          ...PageDataBodyQrCodeGenerator
          ...PageDataBodyQrLinks
          ...PageDataBodyDynamicQrLink
          ...PageDataBodyYohnPremium
          ...PageDataBodyCardFormats
          ...PageDataBodyCardInAction
          ...PageDataBodyImagesAndTextNew
          ...PageDataBodyCustomerReviews
          ...PageDataBodyLostAndFound
          ...PageDataBodyCreateWalletCard
          ...PageDataBodyHeroSlider
          ...PageDataBodyFeatures2
          ...PageDataBodyFeatures3
          ...PageDataBodyVersatileForms
          ...PageDataBodyContactForm2
          ...PageDataBodyImageAndText
          ...PageDataBodyBusinessCardForm
          ...PageDataBodyPremiumBusinessCardForm
          ...PageDataBodyBusinessCardReader
          ...PageDataBodyBusinessCardLanding
          ...PageDataBodyFeaturesNumList
          ...PageDataBodyFeatures5
          ...PageDataBodyContactForm3
          ...PageDataBodyHeroBgImage
          ...PageDataBodyEmbedHtml
          ...PageDataBodyPartners
          ...PageDataBodyFeaturesNew
          ...PageDataBodyLangsHeader
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }
  }
`

export default withPrismicPreview(PageTemplate)

import * as React from 'react';
import { useState } from 'react';
import { graphql } from 'gatsby';
import { useForm } from "react-hook-form";
import { PrismicRichText } from '@prismicio/react'
import { callHook } from '../utils/utils';
var validator = require('email-validator')

export const BusinessCardLanding = ({ slice, index }) => {
  const { title } = slice.primary;

  const [isSend, setSend] = useState(false);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isBtnDisabled, setBtnDisabled] = useState(false)
  const [link, setLink] = useState("");

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isSubmitted },
  } = useForm({
    mode: 'onChange',
  })

  let gclid = ""
  let msclkid = ""
  if (typeof window !== 'undefined'){
    const pageUrl = new URL(window.location);
    gclid = pageUrl.searchParams.get("gclid");
    //console.log('gclid: ', gclid);
    msclkid = pageUrl.searchParams.get("msclkid");
    //console.log('msclkid: ', msclkid);
  }

  const onSubmit = async (data) => {
    setBtnDisabled(true);
    console.log('data: ', data);
    const url = "https://n8n.yohn.io/webhook/landing-gen"
    const mode = "no-cors"
    let qrData = {type: "FreeBusinessLanding", email: data.email, data: "", gclid: gclid, msclkid: msclkid}

    const person = {
      "surname": data.lastName,
      "forename": data.firstName,
      "emailAddress": data.email,
      "mobileNumber": data.phone
    };

    const text = [
      "BEGIN:VCARD",
      "VERSION:3.0",
      `N:${data.lastName};${data.firstName}`,
      `FN:${data.firstName} ${data.lastName}`,
    ];

    if (data.email) {
      text.push(`EMAIL;TYPE=INTERNET:${data.email}`);
    }
    if (data.phone) {
      text.push(`TEL;CELL:${data.phone}`);
    }
    text.push("END:VCARD")

    const qr_pass = {
      "person": person,
      "vcard": text.join("\r\n")
    }

    qrData.data = text.join('\r\n');

    console.log('qr_pass: ', qr_pass);

    qrData.qrPass = qr_pass;
    window.scrollTo(0, 0)
    setLoading(true);
    const r = await callHook({url, uid:'yohn-qr-code', params:qrData, mode});
    console.log('callHook r: ', r);
    if (r && r.success === true) {
      setError(false);
      setSend(true);
      setLoading(false);
      setBtnDisabled(false);
      setLink(r.html)
    } else {
      setError(true);
    }
  }

  const input_label_first_name = "First name"
  const input_label_last_name = "Last name"
  const input_label_email = "Email"
  const input_label_phone = "Phone"
  const text_agreement = "I agree to the Terms of Service, Privacy Policy and Use of Cookies."
  const btn_label_generate = "Generate now!"
  const text_test = "Test for 7 days - absolutely for free, no credit card needed"
  const text_just_a_sec = "Just a second"
  const text_we_are_generating = "We are generating your business card"
  const text_card_ready = "Your free digital business landing is ready!"
  const title_error = "An error has occurred"
  const text_error = "Please, try again later, server is not available now."
  const btn_label_try_again = "Try again"

  return (
    <>
      <div className={'py-6'}>
        <div className={`container ${isSend ? "py-lg-5" : ""}`}>
          <div className="row g-5">
            {isError ?
              <div className="col d-flex flex-column justify-content-center align-items-center text-center">
                <h3>{title_error}</h3>
                <p>{text_error}</p>
                <button onClick={()=>{setError(false), setLoading(false), setBtnDisabled(false)}} className={"btn btn-sm text-uppercase btn-primary w-auto"}>{btn_label_try_again}</button>
              </div>
            :
              <>
                {isLoading ?
                  <div className="col d-flex flex-column justify-content-center align-items-center text-center">
                    <div className="loader mx-auto"></div>
                    <h3>{text_just_a_sec}</h3>
                    <p>{text_we_are_generating}</p>
                  </div>
                    :
                  <div className="col pt-lg-5">
                    {isSend ? 
                      <p className="h3 text-center mb-3 mt-lg-5">{text_card_ready}</p>
                    :
                      <h1 className="h2 mb-0 text-center" id="title">{title}</h1>
                    }
                    {!isSend ? 
                      <div className="mt-5">
                        <form
                          id="freebusinesscard"
                          className="text-center col"
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <div className="row g-3">
                            <div className={'position-relative d-flex col-12 col-md-6'}>
                              <input
                                placeholder={`${input_label_first_name} *`}
                                className={'form-control' + ' ' + (errors.full && 'is-invalid')}
                                {...register('firstName', { required: true })}
                              />
                            </div>
                            <div className={'position-relative d-flex col-12 col-md-6'}>
                              <input
                                placeholder={`${input_label_last_name} *`}
                                className={'form-control' + ' ' + (errors.full && 'is-invalid')}
                                {...register('lastName', { required: true })}
                              />
                            </div>
                            <div className={'position-relative d-flex flex-column col-12 col-md-6'}>
                              <input
                                placeholder={`${input_label_email} *`}
                                className={'form-control' + " " + (errors.email && isSubmitted ? "is-invalid" : "")}
                                {...register('email', {required: true, validate: (input) => validator.validate(input)})}
                              />
                              {errors.email && isSubmitted && <div className="invalid-feedback">{'Invalid email address'}</div>}
                            </div>
                            <div className={'position-relative d-flex col-12 col-md-6'} style={{height:"fit-content"}}>
                              <input
                                placeholder={`${input_label_phone}`}
                                className={'form-control' + ' ' + (errors.full && 'is-invalid')}
                                {...register('phone', { required: false })}
                              />
                            </div>
                          </div>
                          <div className={'d-flex justify-content-center mt-4'}>
                            <label className="form-check-label d-flex" for="flexCheckChecked">
                              <input
                                className="form-check-input me-2"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                required
                              />
                              <span className="fs-0875">
                                <PrismicRichText field={text_agreement.richText} />
                                {text_agreement}
                              </span>
                            </label>
                          </div>
                          <input
                            type="submit"
                            value={btn_label_generate}
                            className={'mt-4 btn btn-sm btn-primary text-uppercase'}
                            disabled={isBtnDisabled}
                          />
                        </form>
                        <p className="mt-3 mb-0 h6 text-center" style={{fontSize:"14px"}}>{text_test}</p>
                      </div>
                    :
                      <div className="text-center mx-auto">
                        <div className="col-auto mt-4">
                          <div className="d-flex flex-column justify-content-center gap-3 gap-xl-4">
                            <div className="mt-4">
                              <a href={link}>Business Card Landing</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                }
              </>
            }
          </div>
        </div>
      </div>
    </>
  )
};

export const query = graphql`
  fragment PageDataBodyBusinessCardLanding on PrismicPageDataBodyBusinessCardLanding {
    primary {
      title
    }
  }
`;
